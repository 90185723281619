import React, { useState } from 'react';
import { Loader } from 'semantic-ui-react';
import styled, { ThemedStyledProps, DefaultTheme } from 'styled-components';
import { useGoogleAds } from 'hooks/useGoogleAds';
import { PageHelmet } from 'components/atoms/PageHelmet/PageHelmet';
import { HeaderPageWrapper } from 'components/templates/HeaderPageWrapper/HeaderPageWrapper';
import { Footer } from 'components/molecules/Footer/Footer';
import { StickyLogInRegisterFooter } from '../PageLanding/StickyLogInRegisterFooter';
import { isMobileViewPort } from 'utils';
import { Contentful } from 'mxp-schemas';
import { SocialSharing } from 'components/molecules/SocialSharing/SocialSharing';
import { BlockLandingPageRenderer } from 'components/organisms/BlockRenderer/BlockLandingPageRenderer';
import { BlockToolkitPageRenderer } from 'components/organisms/BlockRenderer/BlockToolkitPageRenderer';
import { AggregationHero } from 'components/organisms/AggregationHero';
import { ExternalAdBanner, ExternalAdBannerType } from 'components/organisms/ExternalAdBanner/ExternalAdBanner';
import { HeaderStaticLandingPageWrapper } from 'components/templates/HeaderStaticLandingPageWrapper/HeaderStaticLandingPageWrapper';
import { HelpSection } from 'components/organisms/HelpSection/HelpSection';
import { Divider } from 'components/atoms';
import { ContentfulHelpers } from 'mxp-utils';
import { useSelector } from 'react-redux';
import { staticLandingPageContentTypeSlugSelector } from 'modules/staticLandingPagesContent/selectors';
import { PaywallNotificationContainer } from 'containers/PaywallNotificationContainer';
export interface Props {
  isAuth: boolean | null;
  isStaticLandingPageFetched: boolean;
  staticLandingPage: Contentful.StaticLandingPages.IParsedPage | null;
  slug: string;
  skipStaticLandingPageFrontloadRequest: boolean;
  isUserOnBoarded: boolean;
  hasShortcut: boolean;
  fetchStaticLandingPageContent: (slug: string) => void;
  resetSkipLandingPageFrontloadRequest: () => void;
  resetModule: () => void;
  toggleUserShortcuts: ({ topicSlug, isOnEvent, subtopicSlug }: Common.ToggleUserShortcutsProps) => void;
  toggleLoginReload(shouldReload: boolean): void;
  contentItem?: State.ContentItem | null;
  contentIsExclusive: Common.ContentExclusive;
  appInitialized: boolean;
}

export const PageStaticLanding: React.FC<Props> = ({
  isAuth,
  isStaticLandingPageFetched,
  staticLandingPage,
  slug,
  isUserOnBoarded,
  hasShortcut,
  resetModule,
  toggleUserShortcuts,
  toggleLoginReload,
  contentItem,
  contentIsExclusive,
}) => {
  useGoogleAds(staticLandingPage?.externalAdsConfig);
  const [headerHeight, setHeaderHeight] = React.useState(177);
  const targetContainer: any = React.createRef();
  const containerContentRef: any = React.createRef();
  const [showContent, setShowContent] = useState(false);

  const noFollow = [
    'how-to-optimize-decision-making',
    'pricing-objections-tool-for-cpas',
    'salt-roadmap-and-resource-center',
    'considerations-of-esg-related-matters',
    'data-analytics-integration-and-tools',
    'five-financial-storytelling-tips',
  ];

  React.useEffect(() => {
    if (containerContentRef?.current && targetContainer?.current) {
      return setHeaderHeight(targetContainer.current.clientHeight - containerContentRef.current.clientHeight);
    }
  }, [targetContainer, containerContentRef]);

  React.useEffect(() => resetModule, [resetModule]);
  const footerHeight: number = !isAuth ? 163 : 103; // px
  const isMobile: boolean = isMobileViewPort();

  const handleShortcutClick = React.useCallback((): void => {
    toggleUserShortcuts({
      topicSlug: staticLandingPage?.title || '',
      isOnEvent: !hasShortcut,
      subtopicSlug: `${staticLandingPage?.contentCategory?.slug || ''}/$LANDING$/${slug}`,
    });
  }, [slug, staticLandingPage, toggleUserShortcuts, hasShortcut]);

  const contentTypeSlug = useSelector(staticLandingPageContentTypeSlugSelector);
  const isCenterMembershipJourney = ContentfulHelpers.isCenterMembershipContentType(contentTypeSlug);

  if (!isStaticLandingPageFetched) {
    return (
      <HeaderPageWrapper>
        <Loader active>Loading</Loader>
        <StyledFooter isAuth={isAuth} />
        {!isAuth && <StickyLogInRegisterFooter />}
      </HeaderPageWrapper>
    );
  }

  const renderRunningLandingPageBlocks = (
    runningBlocks: Contentful.ModularContent.Block[],
    isFooter?: boolean
  ): React.ReactNode => {
    if (runningBlocks?.length) {
      const blockRender = {
        blocks: runningBlocks,
        isFullPagePagination: false,
        headerHeight,
        footerHeight,
        isMobile,
        isAuth,
        isUserOnBoarded,
        targetContainer,
        containerContentRef,
        isFooter,
        contentIsExclusive,
        contentItem,
        isStaticLandingPage: true,
      };
      return <BlockLandingPageRenderer blockRenderLandingPages={blockRender} />;
    }
    return null;
  };

  const renderRunningToolkitPageBlocks = (mainColumn: any[], leftColumn: any[]): React.ReactNode => {
    const blockRender = {
      mainColumn,
      leftColumn,
      targetContainer,
      containerContentRef,
      isMobile,
      isAuth,
      isUserOnBoarded,
      contentIsExclusive,
      contentItem,
      isStaticLandingPageFetched,
    };
    return <BlockToolkitPageRenderer blockRenderToolkitPages={blockRender} />;
  };

  const content = (
    <React.Fragment>
      {staticLandingPage?.externalAdsConfig?.topAdBanner && <ExternalAdBanner type={ExternalAdBannerType.TOP} />}
      {staticLandingPage?.header?.length ? (
        renderRunningLandingPageBlocks(staticLandingPage?.header as Contentful.ModularContent.Block[])
      ) : (
        <AggregationHero
          subtopicName={staticLandingPage?.title}
          topicSummary={staticLandingPage?.description}
          hasShortcut={hasShortcut}
          isSubtopicPage={false}
          isToolkitPage={true}
          showCopyLinkButton={true}
          onShortcutClick={handleShortcutClick}
        />
      )}
      {showContent &&
        renderRunningToolkitPageBlocks(staticLandingPage?.mainColumn || [], staticLandingPage?.leftColumn || [])}
      {renderRunningLandingPageBlocks(staticLandingPage?.footer as Contentful.ModularContent.Block[], true)}
      {staticLandingPage?.externalAdsConfig?.bottomAdBanner && <ExternalAdBanner type={ExternalAdBannerType.BOTTOM} />}
      <PaywallNotificationContainer isContentExclusive={contentIsExclusive} setShowContent={setShowContent} />
      <StyledSubFooter>
        <StyledDivider />
        <StyledHelpSection isMembershipJourney={true} isOnCenterMembershipLandingPage={isCenterMembershipJourney} />
      </StyledSubFooter>

      <StyledFooter isAuth={isAuth} />
      {!isAuth && <StickyLogInRegisterFooter />}
    </React.Fragment>
  );

  return (
    <StyledDiv>
      <PageHelmet
        isNoFollow={noFollow.includes(slug)}
        isNoIndex={staticLandingPage?.noIndex}
        meta={{ title: staticLandingPage?.title, description: staticLandingPage?.description }}
        openGraph={{
          title: staticLandingPage?.title,
          description: staticLandingPage?.description,
          image: staticLandingPage?.ogImage && `https:${staticLandingPage.ogImage}`,
          passUrl: true,
        }}
      />
      {staticLandingPage?.simpleHeader ? (
        <HeaderStaticLandingPageWrapper>{content}</HeaderStaticLandingPageWrapper>
      ) : (
        <HeaderPageWrapper contentRef={targetContainer}>{content}</HeaderPageWrapper>
      )}
      <SocialSharing title={staticLandingPage?.title} isSticky />
    </StyledDiv>
  );
};

interface FooterProps {
  isAuth: boolean | null;
}

const StyledFooter = styled(Footer)<ThemedStyledProps<FooterProps, DefaultTheme>>`
  ${props =>
    !props.isAuth &&
    `
    ::after {
      display: block;
      content: '';
      height: ${props.theme.pxToRem(60)};
  `}
`;

const StyledDiv = styled.div`
  ${props => props.theme.mediaQueries.desktopOnly} {
    height: ${props => props.theme.pxToRem(1)};
    min-height: 100%;
  }
`;

const StyledSubFooter = styled.div`
  box-sizing: border-box;
  width: ${props => props.theme.pxToRem(1123)};
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
    padding-left: ${props => props.theme.pxToRem(21)};
    padding-right: ${props => props.theme.pxToRem(21)};
  }
`;

const StyledHelpSection = styled(HelpSection)`
  margin: ${props => props.theme.pxToRem(24)} 0 ${props => props.theme.pxToRem(56)};
`;

const StyledDivider = styled(Divider)`
  margin-top: 0;
`;

import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Dispatch } from 'redux';
import { frontloadConnect } from 'react-frontload';
import { PageStaticLanding, Props } from 'components/pages/PageStaticLanding/PageStaticLanding';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import {
  staticLandingPageFetchedSelector,
  staticLandingPageSelector,
  skipStaticLandingPageFrontloadRequestSelector,
  staticLandingPageAccessRestrictionSelector,
} from 'modules/staticLandingPagesContent/selectors';
import { contentIsExclusiveSelector } from 'modules/content/selectors';
import {
  fetchStaticLandingPageContent,
  resetModule,
  resetSkipStaticLandingPageFrontloadRequest,
} from 'modules/staticLandingPagesContent/actions';
import { isAuthSelector, userHasPrefSelector, userShortcutsUrlsSelector } from 'modules/user/selectors';
import { toggleUserShortcuts } from 'modules/user/actions';
import { appInitializedSelector } from 'modules/app/selectors';
import { store } from '../store';

const mapActionCreators = (dispatch: Dispatch) => ({
  async fetchStaticLandingPageContent(slug: string): Promise<void> {
    await dispatch(fetchStaticLandingPageContent(slug)).catch(() => dispatch(push(getPath(Routes.NOT_FOUND))));
  },
  resetModule(): void {
    dispatch(resetModule());
  },
  resetSkipLandingPageFrontloadRequest(): void {
    dispatch(resetSkipStaticLandingPageFrontloadRequest());
  },
  toggleUserShortcuts({ topicSlug, isOnEvent, subtopicSlug }: Common.ToggleUserShortcutsProps): void {
    dispatch(toggleUserShortcuts({ topicSlug, isOnEvent, subtopicSlug }));
  },
});

const mapStateToProps = (state: State.Root, ownProps: { match: { params: { slug: string } } }) => {
  const { match } = ownProps;
  const { slug } = match && match.params ? match.params : { slug: '' };
  const isAuth: boolean | null = isAuthSelector(state);
  const userHasPref: boolean = userHasPrefSelector(state);
  const isUserOnBoarded = Boolean(isAuth) && userHasPref;
  const contentItem = staticLandingPageAccessRestrictionSelector(state);
  const contentIsExclusive: Common.ContentExclusive = contentIsExclusiveSelector(state);

  const userHasShortcutSelector = createSelector(
    [userShortcutsUrlsSelector, staticLandingPageSelector],
    (userShortcutsUrls: string[], staticLandingPage: any): boolean => {
      if (!userShortcutsUrls || !userShortcutsUrls.length || !slug || !staticLandingPage || !staticLandingPage.title) {
        return false;
      }
      return userShortcutsUrls.includes(
        `${staticLandingPage.title}${staticLandingPage.contentCategory.slug || ''}/$LANDING$/${slug}`
      );
    }
  );
  return {
    isAuth,
    slug,
    isStaticLandingPageFetched: staticLandingPageFetchedSelector(state),
    staticLandingPage: staticLandingPageSelector(state),
    skipStaticLandingPageFrontloadRequest: skipStaticLandingPageFrontloadRequestSelector(state),
    isUserOnBoarded,
    hasShortcut: userHasShortcutSelector(state),
    contentItem,
    contentIsExclusive,
    appInitialized: appInitializedSelector(state),
  };
};
const frontload = async (props: Props) => {
  const state = store?.getState();
  const appInitialized = appInitializedSelector(state);
  if (props.skipStaticLandingPageFrontloadRequest && appInitialized) {
    return props.resetSkipLandingPageFrontloadRequest();
  }
  return props.fetchStaticLandingPageContent(props.slug);
};

const options = {
  onUpdate: true,
  _experimental_updateFunc: (prevProps: Props, newProps: Props) =>
    prevProps.isAuth !== newProps.isAuth ||
    prevProps.appInitialized !== newProps.appInitialized ||
    prevProps.slug !== newProps.slug,
};

export const PageStaticLandingContainer = connect(
  mapStateToProps,
  mapActionCreators
)(frontloadConnect(frontload, options)(PageStaticLanding));

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { StaticLandingPageHeader } from 'components/molecules/StaticLandingPageHeader/StaticLandingPageHeader';
import { setSearchModalExpand } from 'modules/layouts';
import { clearSuggestions } from 'modules/search/actions';

const mapActionCreators = (dispatch: Dispatch) => ({
  navigate(path: string): void {
    dispatch(push(path));
  },

  setSearchModal(expanded: boolean): void {
    dispatch(setSearchModalExpand(expanded));
  },

  clearSuggestions(): void {
    dispatch(clearSuggestions());
  },
});

export const HeaderStaticLandingPageCombinedContainer = connect(null, mapActionCreators)(StaticLandingPageHeader);

import React from 'react';
import { Contentful } from 'mxp-schemas';
import { BlockRenderer } from 'components/organisms/BlockRenderer/BlockRenderer';

interface Props {
  blockRenderLandingPages: BlockRenderLandingPages;
}

interface BlockRenderLandingPages {
  blocks: Contentful.ModularContent.Block[];
  isFullPagePagination: boolean;
  isUserOnBoarded: boolean;
  headerHeight?: number;
  footerHeight?: number;
  withHeader?: boolean;
  withFooter?: boolean;
  targetContainer?: any;
  containerContentRef?: any;
  isFirstBlock?: boolean;
  isMobile?: boolean;
  isAuth: boolean | null;
  isFooter?: boolean;
  contentItem?: State.ContentItem | null;
  contentIsExclusive?: Common.ContentExclusive;
  isStaticLandingPage?: boolean;
  isHomePage?: boolean;
}

export const BlockLandingPageRenderer: React.FC<Props> = ({ blockRenderLandingPages }) => {
  const renderPage = (): React.ReactNode => {
    return blockRenderLandingPages.blocks.map((block: Contentful.ModularContent.Block, idx: number) => {
      const isFirstBlock: boolean = blockRenderLandingPages.isFooter ? false : idx === 0;

      if (block) {
        const blockRender = {
          block,
          isFirstBlock,
          isFullPagePagination: false,
          headerHeight: blockRenderLandingPages.headerHeight,
          footerHeight: blockRenderLandingPages.footerHeight,
          isMobile: blockRenderLandingPages.isMobile,
          isAuth: blockRenderLandingPages.isAuth,
          isUserOnBoarded: blockRenderLandingPages.isUserOnBoarded,
          targetContainer: blockRenderLandingPages.targetContainer,
          containerContentRef: blockRenderLandingPages.containerContentRef,
          isLandingPage: true,
          contentIsExclusive: blockRenderLandingPages.contentIsExclusive,
          contentItem: blockRenderLandingPages.contentItem,
          isStaticLandingPage: blockRenderLandingPages.isStaticLandingPage,
          isInHeader: !blockRenderLandingPages.isFooter,
          isHomePage: blockRenderLandingPages.isHomePage,
        };

        return (
          <React.Fragment key={block.id}>
            <BlockRenderer blockRenderLandingPage={blockRender} />
          </React.Fragment>
        );
      }
      return null;
    });
  };

  return renderPage() as JSX.Element;
};

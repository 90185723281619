import React from 'react';
import styled from 'styled-components';
import { Container } from 'semantic-ui-react';
import { OnlyDesktop } from 'components/atoms';
import { LogInRegisterButtons } from 'components/molecules/LogInRegisterButtons';

export const StickyLogInRegisterFooter: React.FC = () => (
  <StyledContainer>
    <Container>
      <Wrapper>
        {/* Following div is fix for SSR. Do not remove! */}
        <div>
          <OnlyDesktop>
            <p>There’s more to see when you sign in to AICPA-CIMA.com</p>
          </OnlyDesktop>
        </div>
        <StyledLogInRegisterButtons
          isStickyButtons={true}
          logInButtonTestId="plh-login"
          registerButtonTestId="plh-register"
        />
      </Wrapper>
    </Container>
  </StyledContainer>
);

const StyledContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 109;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.97);
  opacity: 0.9;
  backdrop-filter: blur(${props => props.theme.pxToRem(3)});
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${props => props.theme.pxToRem(60)};

  p {
    margin-bottom: 0;
    line-height: 1.33;
  }
`;

const StyledLogInRegisterButtons = styled(LogInRegisterButtons)`
  width: ${props => props.theme.pxToRem(360)};

  button {
    ${props => props.theme.mediaQueries.desktopOnly} {
      max-width: ${props => props.theme.pxToRem(170)};
    }

    :first-child {
      margin-right: ${props => props.theme.pxToRem(20)};
    }
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
  }
`;

import React, { MutableRefObject } from 'react';
import { useSelector } from 'react-redux';
import { Routes } from 'constants/index';
import { getPath } from 'utils';
import { useLocation } from 'react-router';
import styled from 'styled-components/macro';
import { Sticky } from 'semantic-ui-react';
import { HeaderStaticLandingPageCombinedContainer } from 'containers/HeaderStaticLandingPageCombinedContainer';
import { SlideMobileHeaderMenuContainer } from 'components/App/lazy-imports';
import { OnlyMobile, OnlyDesktop } from 'components/atoms';
import { DefaultTheme, ThemedStyledProps } from 'styled-components';
import { SearchModalContainer } from '..';
import { burgerExpandedSelector } from 'modules/layouts/selectors';
import { BlogBanner } from 'components/molecules/BlogBanner/BlogBanner';

interface Props {
  children: any;
  contentRef?: MutableRefObject<HTMLDivElement | null>;
  contentInnerRef?: MutableRefObject<HTMLDivElement | null>;
  contentOnScroll?: () => void;
  fixedFooter?: any;
  className?: string;
  stickMobileHeader?: boolean;
  hideHeader?: boolean;
  headerButton?: any;
}

export const HeaderStaticLandingPageWrapper: React.FC<Props> = ({
  children,
  contentRef,
  contentOnScroll,
  fixedFooter,
  className,
  contentInnerRef,
  stickMobileHeader = true,
  // fix for Safari iphone hideHeader - force header to be removed if something needs to be on top of it
  hideHeader = false,
  headerButton,
}) => {
  const burgerExpanded = useSelector(burgerExpandedSelector);
  const location = useLocation();

  const isPageBlogFeed = getPath(Routes.BLOG_FEED) === location.pathname;

  const renderMobileHeader = React.useMemo((): React.ReactNode => {
    if (stickMobileHeader) {
      return (
        /* this div fixes header in ssr */
        <div>
          <StyledSticky context={contentRef}>
            <HeaderStaticLandingPageCombinedContainer />
          </StyledSticky>
          {isPageBlogFeed && !burgerExpanded && <BlogBanner />}
        </div>
      );
    }

    return (
      <>
        <HeaderStaticLandingPageCombinedContainer />
        {isPageBlogFeed && !burgerExpanded && <BlogBanner />}
      </>
    );
  }, [isPageBlogFeed, burgerExpanded, stickMobileHeader, contentRef]);

  const renderDesktopHeader = React.useMemo(
    (): React.ReactNode => (
      <>
        <HeaderStaticLandingPageCombinedContainer />
        {isPageBlogFeed && <BlogBanner />}
      </>
    ),
    [isPageBlogFeed]
  );

  return (
    <>
      <Wrapper
        ref={contentRef}
        onScroll={contentOnScroll}
        className={className}
        burgerExpanded={burgerExpanded}
        style={{ width: '100%' }}
      >
        {/* this div fixes header in ssr */}
        <div>{!hideHeader && <OnlyMobile>{renderMobileHeader}</OnlyMobile>}</div>
        {/* this div fixes header in ssr */}

        <div>
          <OnlyDesktop>{renderDesktopHeader}</OnlyDesktop>
        </div>
        {burgerExpanded && <SlideMobileHeaderMenuContainer />}
        {!burgerExpanded && (
          <Container ref={contentInnerRef} onScroll={contentOnScroll} stickMobileHeader={stickMobileHeader}>
            {children}
          </Container>
        )}
        {burgerExpanded ? null : fixedFooter}
      </Wrapper>
      <SearchModalContainer />
    </>
  );
};

const Wrapper = styled.div<{ burgerExpanded?: boolean }>`
  display: flex;
  flex-direction: column;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;
  }

  ${props => props.theme.mediaQueries.desktopOnly} {
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    background: transparent !important;
    overflow: visible;
  }
`;

const StyledSticky = styled(Sticky)`
  .ui.sticky {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    background: transparent;
    ${props => props.theme.mediaQueries.mobileOnly} {
      background: white !important;
    }
  }
`;

const Container = styled.div<ThemedStyledProps<{ stickMobileHeader: boolean }, DefaultTheme>>`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    min-height: 65vh;
  }

  // This was setting the background automatically to white
  ${props => props.theme.mediaQueries.mobileOnly} {
    &&& {
      background: transparent !important;
      -webkit-overflow-scrolling: touch;
    }
  }
`;

import React from 'react';
import { HeaderPlain } from 'components/molecules/HeaderPlain/HeaderPlain';
import { OnlyDesktop, OnlyMobile } from 'components/atoms';
import styled from 'styled-components';
import { getZIndexFor, Z_INDEX_ORDER } from 'utils/getZIndexFor';
import { StaticLandingPageHeaderButtons } from 'components/molecules/StaticLandingPageHeaderButtons/StaticLandingPageHeaderButtons';

interface Props {
  navigate: (path: string) => void;
  setSearchModal: (expanded: boolean) => void;
  clearSuggestions: () => void;
}

export const StaticLandingPageHeader = React.memo<Props>(props => {
  const { navigate, setSearchModal, clearSuggestions } = props;
  return (
    // TODO: To be converted to desktop CSS
    <HeaderCombinedContainer data-testid="header-combined">
      <OnlyDesktop>
        <HeaderPlain navigate={navigate} clearSuggestions={clearSuggestions} setSearchModal={setSearchModal} />
      </OnlyDesktop>
      <OnlyMobile>
        <StyleDiv>
          <StaticLandingPageHeaderButtons
            logInButtonTestId="uw-login"
            registerButtonTestId="uw-register"
            navigate={navigate}
          />
        </StyleDiv>
      </OnlyMobile>
    </HeaderCombinedContainer>
  );
});

const StyleDiv = styled.div`
  padding: 20px;
  opacity: 0.9;
`;

const HeaderCombinedContainer = styled.div`
  position: relative;
  z-index: ${getZIndexFor(Z_INDEX_ORDER.HeaderCombinedContainerDesk)};
  background: ${props => props.theme.colors.neutralWhite};

  ${props => props.theme.mediaQueries.mobileOnly} {
    z-index: ${getZIndexFor(Z_INDEX_ORDER.HeaderCombinedContainerMob)};
    background: transparent !important;
  }
`;
